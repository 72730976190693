.borderTopLeft {
    background-image: linear-gradient(90deg, #469C2E 50%, transparent 50%), linear-gradient(90deg, #469C2E 50%, transparent 50%), linear-gradient(0deg, #469C2E 50%, transparent 50%), linear-gradient(0deg, #469C2E 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 5px,0 0, 0 0,5px 15px;
    background-position: left top, right bottom, left bottom, right   top;
    width: 285px;
    height: 120px;
    borderStyle: 'dashed';
    animation: border-dance 0.4s infinite linear;
    }

.borderTopRight {
    background-image: linear-gradient(90deg, #469C2E 50%, transparent 50%), linear-gradient(90deg, #469C2E 50%, transparent 50%), linear-gradient(0deg, #469C2E 50%, transparent 50%), linear-gradient(0deg, #469C2E 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 5px,0 0,5px 15px, 0 0;
    background-position: left top, right bottom, left bottom, right   top;
    width: 285px;
    height: 120px;
    borderStyle: 'dashed';
    animation: border-dance 0.4s infinite linear;
    }

.borderBottomLeft {
    background-image: linear-gradient(90deg, #168CBF 50%, transparent 50%), linear-gradient(90deg, #168CBF 50%, transparent 50%), linear-gradient(0deg, #168CBF 50%, transparent 50%), linear-gradient(0deg, #168CBF 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 0 0, 15px 5px,0 0,5px 15px;
    background-position: left top, right bottom, left bottom, right   top;
    width: 285px;
    height: 120px;
    borderStyle: 'dashed';
    animation: border-dance-opposite 0.4s infinite linear;
    }


    @keyframes border-dance {
        0% {
            background-position: left top, right bottom, left bottom, right   top;
        }
        100% {
            background-position: left 15px top, right 15px bottom , left bottom 15px , right   top 15px;
        }
        }

    @keyframes border-dance-opposite {
        100% {
            background-position: left top, right bottom, left bottom, right   top;
        }
        0% {
            background-position: left 15px top, right 15px bottom , left bottom 15px , right   top 15px;
        }
        }